import type { ParsedUrlQuery } from 'querystring';

import styled from '@emotion/styled';
import { Body, Button } from '@sumup/circuit-ui';
import { Delete } from '@sumup/icons';
import type { FC } from 'react';

import { useCoupon } from 'checkout/hooks/useCoupon';
import { useGetOrder } from 'shared/hooks/orders/useGetOrder';
import { useResetOrderState } from 'shared/hooks/orders/useResetOrderState';
import {
  shouldResetOrder,
  shouldUnplaceOrder,
  unplaceOrder,
} from 'shared/infra/storefront/orders';

export interface Props {
  couponCode: string;
  couponCodeRemoveButtonLabel: string;
  couponDiscountAppliedLabel: string;
  discountAppliedText: string;
  formattedDiscountAmount: string;
  orderId: string;
  query: ParsedUrlQuery;
  showRemoveButton: boolean;
}

const DiscountWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`;

const DiscountValue = styled.div`
  align-items: flex-end;
  display: flex;
  flex-direction: column;
`;

const DiscountRemoveBtn = styled(Button)`
  border-radius: 0;
  white-space: nowrap;
`;

export const Discount: FC<Props> = ({
  couponCode,
  couponCodeRemoveButtonLabel,
  couponDiscountAppliedLabel,
  discountAppliedText,
  formattedDiscountAmount,
  orderId,
  query,
  showRemoveButton,
}) => {
  const { addCoupon, resetCoupon } = useCoupon();
  const getOrder = useGetOrder();
  const resetOrder = useResetOrderState();

  const handleCouponRemove = async (): Promise<void> => {
    await addCoupon('', orderId);
    const order = await getOrder(orderId);

    if (shouldResetOrder(order.status, order?.payment_method?.reference)) {
      resetOrder();

      return;
    }

    if (shouldUnplaceOrder(order.status, order?.payment_method?.reference)) {
      await unplaceOrder(order.id);
    }

    resetCoupon();
  };

  if (!query.prc) {
    return (
      <DiscountWrapper>
        <span>
          <Body as="span">{couponDiscountAppliedLabel} </Body>
          <Body variant="highlight">{couponCode}</Body>
        </span>
        <DiscountValue>
          <Body>{formattedDiscountAmount}</Body>

          {showRemoveButton && (
            <DiscountRemoveBtn
              size="s"
              type="submit"
              variant="tertiary"
              onClick={handleCouponRemove}
            >
              <Delete
                css={{ marginRight: 'var(--cui-spacings-bit)' }}
                size="16"
              />
              <Body as="span" size="two" variant="highlight">
                {couponCodeRemoveButtonLabel}
              </Body>
            </DiscountRemoveBtn>
          )}
        </DiscountValue>
      </DiscountWrapper>
    );
  }

  return (
    <>
      <Body>{discountAppliedText}</Body>
      <span>{formattedDiscountAmount}</span>
    </>
  );
};
