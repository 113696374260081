import type {
  Promotion as CLPromotion,
  Coupon,
  FixedAmountPromotion,
  FixedPricePromotion,
  PercentageDiscountPromotion,
} from '@commercelayer/sdk';
import type { ListResponse } from '@commercelayer/sdk/lib/cjs/resource';

import { client } from 'shared/infra/commerceLayer/client';
import { processCouponCodeToOrder } from 'shared/infra/commerceLayer/utils';

export type SupportedPromotion =
  | CLPromotion
  | FixedAmountPromotion
  | FixedPricePromotion
  | PercentageDiscountPromotion;

export interface Promotion {
  couponCode: Coupon['code'];
  name: SupportedPromotion['name'];
  purchaseLimit?: number;
  type: SupportedPromotion['type'];
}

export const fetchPromotionByCouponCode = async (
  couponCode: string,
): Promise<ListResponse<CLPromotion>> => {
  const clClient = await client.get();

  const promotion = await clClient.promotions.list({
    include: [
      'sku_list_promotion_rule.skus',
      'sku_list_promotion_rule.sku_list.bundles',
    ],
    filters: {
      coupon_codes_promotion_rule_coupons_code_eq:
        processCouponCodeToOrder(couponCode),
    },
  });

  return promotion;
};

export const parsePromotionData = (
  promotion: SupportedPromotion,
  couponCode: string,
): Promotion => ({
  name: promotion.name,
  couponCode,
  type: promotion.type,
  purchaseLimit: promotion.metadata.purchase_limit as number,
});

export const isPromotionValid = (promotion: SupportedPromotion): boolean => {
  if (!promotion) {
    return false;
  }

  const isPromotionActive = Boolean(
    promotion && promotion.active && promotion.sku_list_promotion_rule,
  );
  const isPromotionSupported =
    promotion?.type === 'fixed_amount_promotions' ||
    promotion?.type === 'fixed_price_promotions' ||
    promotion?.type === 'percentage_discount_promotions';

  return isPromotionActive && isPromotionSupported;
};

export const parsePromotionsByCouponCode = (
  promotions: SupportedPromotion[],
  couponCode: string,
): Record<string, Promotion> =>
  promotions.reduce((mem, promo) => {
    if (!isPromotionValid(promo)) {
      return mem;
    }

    const promoBundleMap =
      promo.sku_list_promotion_rule?.sku_list?.bundles?.reduce(
        (promoByBundle, bundleSku) => ({
          ...promoByBundle,
          [bundleSku.code]: parsePromotionData(promo, couponCode),
        }),
        {},
      );

    const promoSkuMap = promo.sku_list_promotion_rule?.skus?.reduce(
      (promoBySku, sku) => ({
        ...promoBySku,
        [sku.code]: parsePromotionData(promo, couponCode),
      }),
      {},
    );

    return { ...mem, ...promoSkuMap, ...promoBundleMap };
  }, {});

export const getSkuPromotionByCouponCode = async (
  couponCode: string,
): Promise<Record<string, Promotion>> => {
  const promotions = await fetchPromotionByCouponCode(couponCode);

  return parsePromotionsByCouponCode(promotions, couponCode);
};

export const getPromotionsByCouponCode = async (
  coupons: string[],
): Promise<Record<string, Promotion>> => {
  // We have to fetch each promo code separately because we otherwise wouldn't
  // know which coupon belongs to which promotoin.
  // CommerceLayer doesn't allow to include coupons in the response (for fraud reasons)
  const promotionsBySku = await Promise.all(
    coupons.map(getSkuPromotionByCouponCode),
  );

  const parsedPromotions = promotionsBySku.reduce(
    (acc, promotion) => ({
      ...acc,
      ...promotion,
    }),
    {},
  );

  return parsedPromotions;
};
