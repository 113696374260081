import { Body } from '@sumup/circuit-ui';
import { useState, type FC } from 'react';

import type { Product } from 'productSelection/types/products';
import type { IPlan } from 'shared/infra/contentful/contentful';
import { SUMUP_ONE_SKU } from 'src/cart/services/CartOverviewService';

import {
  OverviewWrapper,
  StyledHeadline,
  PlanFeeContainer,
  FeeRateContainer,
  InfoIcon,
  TooltipContainer,
  StyleInvertedTooltip,
  TransactionTypes,
} from '../../OrderOverview.styles';
import {
  DueMonthlyCard,
  type DueMonthlyContent,
} from '../DueMonthlyCard/DueMonthlyCard';

type Props = {
  products?: Product[];
  dueMonthlyContent: DueMonthlyContent;
  defaultTaxRate: number;
  usedInModal?: boolean;
  hasSumUpOne?: boolean;
  defaultPlan?: IPlan;
};
export const PlanCard: FC<Props> = (props) => {
  const {
    products,
    dueMonthlyContent,
    defaultTaxRate,
    usedInModal,
    hasSumUpOne = false,
    defaultPlan,
  } = props;
  const [tooltipVisible, setTooltipVisible] = useState(false);
  const toggleTooltip = (): void => {
    setTooltipVisible(!tooltipVisible);
  };

  const sumUpOneProduct = products?.find(
    (product) => product.code === SUMUP_ONE_SKU,
  );
  const planFields = hasSumUpOne
    ? sumUpOneProduct?.plan?.fields
    : defaultPlan?.fields;
  if (!planFields) {
    return null;
  }

  return (
    <>
      {hasSumUpOne && (
        <DueMonthlyCard
          content={dueMonthlyContent}
          noMargin
          products={products.map((v) => {
            if (v.code === SUMUP_ONE_SKU) {
              return {
                ...v,
                subscription: {
                  total_amount_float: planFields.monthlyCost,
                  tax_amount_float: 0,
                  total_amount_with_taxes_and_discounts_float:
                    planFields.monthlyCost,
                  tax_rate: defaultTaxRate,
                },
              };
            }
            return v;
          })}
          usedInModal={usedInModal}
        />
      )}
      <OverviewWrapper noMargin={!hasSumUpOne} usedInModal={usedInModal}>
        <StyledHeadline as="h2" size="three">
          {planFields.feesLabel}
        </StyledHeadline>
        <PlanFeeContainer>
          <FeeRateContainer>
            <Body>
              {planFields.inPersonFeesLabel}
              {planFields.premiumCardFeeNotice && (
                <>
                  <InfoIcon
                    name="info"
                    aria-label="info"
                    size="16"
                    onMouseEnter={toggleTooltip}
                    onMouseLeave={toggleTooltip}
                    usedInPlanCard
                  />
                  {tooltipVisible && (
                    <TooltipContainer alignedAtBottom>
                      <StyleInvertedTooltip align="bottom" position="bottom">
                        {planFields.premiumCardFeeNotice}
                      </StyleInvertedTooltip>
                    </TooltipContainer>
                  )}
                </>
              )}
            </Body>
            <Body>{planFields.inPersonFeeRate}%</Body>
          </FeeRateContainer>
          {planFields.inPersonTransactionTypes && (
            <TransactionTypes size="two">
              {planFields.inPersonTransactionTypes}
            </TransactionTypes>
          )}
        </PlanFeeContainer>
        <PlanFeeContainer>
          <FeeRateContainer>
            <Body>{planFields.digitalFeesLabel}</Body>
            <Body>{planFields.digitalFeeRate}%</Body>
          </FeeRateContainer>
          {planFields.digitalTransactionTypes && (
            <TransactionTypes size="two">
              {planFields.digitalTransactionTypes}
            </TransactionTypes>
          )}
        </PlanFeeContainer>
      </OverviewWrapper>
    </>
  );
};
